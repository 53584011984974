<template>
  <div class="text-center">
    <form :class="content && content.cssClass" class="relative contact" v-on:submit.prevent="submitContactForm" v-animate-onscroll="'animated fadeIn'">
      <h3 :class="`${false ? 'opacity-0 delay-200' : ''}`" v-animate-onscroll="'animated fadeIn'">{{content ? content.heading : 'Want Help Planning Your Party?'}}</h3>
      <div class="px-6 py-6 pb-8 mt-2 bg-white sm:px-10">
        <div class="flex flex-wrap">
          <div v-for="f in fields" :key="f.index" :class="`relative mb-6 sm:mb-8 ${getWidth(f)} ${errors.indexOf(f.name) > -1 ? 'error' : ''} ${f.showKey && f.showValue.indexOf(model[f.showKey]) < 0 ? 'hidden' : ''}`" :data-error="f.errorMsg">
            <div v-if="!f.showKey || f.showValue.indexOf(model[f.showKey]) > -1">
              <label :for="f.name" :class="`main-label`" v-html="f.label" />
              <div v-if="f.type == 'textarea'">
                <textarea :id="f.name" :name="f.name" :placeholder="f.label" @keyup="getCharsRemaining(f.name)" v-model="model[f.name]" maxlength="256"></textarea>
                <span class="float-left">* No special characters allowed except /</span>
                <span class="float-right">{{charsRemaining}} Characters Remaining</span>
              </div>
              <div v-else-if="f.type == 'date'">
                <date-field :field="f" :model="model[f.name]" :handleUpdate="onCalendarChange" />
              </div>
              <div v-else-if="f.type == 'select'">
                <mobile-select v-if="mobile" :field="f" :model="model" :handleUpdate="updateSelect" />
                <default-select v-else :field="f" :model="model" :handleUpdate="updateSelect" />
              </div>
              <div v-else-if="f.type == 'checkbox'" class="flex">
                <input type="checkbox" :id="f.name" :name="f.name" v-model="model[f.name]" />
                <label :for="f.name" v-html="f.label" />
              </div>
              <input v-else :type="f.type" :id="f.name" :name="f.name" :placeholder="f.label" v-model="model[f.name]" />
            </div>
          </div>
        </div>

        <div v-if="sending">
          <loader color="purple" />
        </div>
        <div v-else-if="complete" class="text-center">
          <div>Thank You!<br/>A member of our team will be in touch with you shortly.</div>
        </div>
        <div v-else-if="failed" class="text-center">
          <div> {{ error }} <br />If this problem persists, please <nuxt-link to="/contact-us/" class="underline">contact us</nuxt-link>.</div>
        </div>
        <div v-else class="flex items-center">
          <button class="pulse purple">Submit</button>
          <div v-if="errors.length > 0" class="mt-10 ml-4 text-red">Please check your form for errors.</div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment'
import { detect } from 'detect-browser'

import Loader from './Loader'
import DefaultSelect from '../forms/Select'
import MobileSelect from '../forms/MobileSelect'
import DateField from '../forms/DateField'
const states = require('~/assets/config/states.json')
const contactFields = require('~/assets/config/contactForm.json')
const eventContactFields = require('~/assets/config/eventContactForm.json')

export default {
  components: {
    Loader,
    DefaultSelect,
    MobileSelect,
    DateField
  },
  props: {
    content: {
      type: Object,
    },
    animate: {
      type: Boolean
    },
    defaults: {
      type: Object
    },
    formName: {
      type: String
    },
    sendKey: {
      type: String
    },
    formData: {
      type: Array
    }
  },
  data: function() {
    let fields = []
    let model = {}

    let formFields = null
    if (this.$route.path.includes('events')) {
      formFields = eventContactFields
    } else {
      formFields = this.formData || contactFields
    }

    formFields.map(f => {
      if(f.name == 'Location') {
        let stateOpts = {}
        Object.keys(this.$store.state.centerData).map(c => {
          let center = this.$store.state.centerData[c]
          if(!stateOpts[center.state]) {
            stateOpts[center.state] = {
              title: states[center.state],
              values: []
            }
          }
          stateOpts[center.state].values.push({
            value: c,
            title: `${center.name}, ${center.state}`
          })
        })

        let locations = []
        Object.keys(stateOpts).map(s => {
          stateOpts[s].values.sort(this.sortOptions)
          locations.push(stateOpts[s])
        })
        locations.sort(this.sortOptions)

        f.options = locations
      }
      fields.push(f)
      model[f.name] = null
    })

    return {
      charsRemaining: 256,
      calendar: null,
      fields: fields,
      model: model,
      errors: [],
      sending: false,
      complete: false,
      failed: false,
      error: ''
    }
  },
  computed: {
    mobile() {
      const browser = detect()
      if(browser) {
        switch(browser.os) {
          case 'iOS':
          case 'Android OS':
            return true
            break
          default:
            return false
        }
      } else {
        return false
      }
    },
    minDate() {
      return moment().add(1, 'day').format('YYYY-MM-DD')
    }
  },
  mounted() {
    if(this.defaults) {
      Object.keys(this.defaults).map(k => {
        this.model[k] = this.defaults[k]
      })
    }
  },
  methods: {
    async submitContactForm() {
      let errors = []
      this.fields.map(f => {
        if(!this.validate(f)) {
          errors.push(f.name)
        }
      })
      this.errors = errors

      if(errors.length < 1 && this.sending == false && this.complete == false) {
        this.failed = false
        this.sending = true
        let eventType = this.model.Booking
        if(!eventType || eventType == 'Virtual') {
          let status = await this.$axios.post(`${this.$api.url()}/mailgun`, {
            table: this.formName || 'Web Booking',
            sendTo: this.sendKey && this.model[this.sendKey] != 'cancellation' ? this.model[this.sendKey] : this.model.Location,
            fields: this.model
          })
          this.sending = false
          this.complete = true
        } else {
          let details = {
            "centerId": this.model.Location,
            // "ownerFirstName": "ownerTest", // need info
            // "ownerLastName": "OT", // need info
            // "salespersonFirstName": "salesPersonTest", // need info
            // "salespersonLastName": "ST", // need info
            "leadName": `${this.model['Last Name']} - ${eventType}`,
            "eventType": eventType,
            "contact" : {
              "firstName": this.model['First Name'],
              "lastName": this.model['Last Name'],
              "mobile": this.model['Phone Number'].replace(/[- )(]/g,''),
              "email": this.model.Email,
              // "optIn": this.model['Accept Terms'] || false
            },
            "eventDate": this.model['Desired Date'],
            "eventTime": "", // need dynamic
            "estimatedAttendance": parseInt(this.model.Attendees),
            "budget": "", // need dynamic
            // "package": this.model['Interested In'], // need real variables
            "description": `${this.model['Desired Date']} -- ${this.model['Additional Info']}`,
            "leadNote": {
              "acceptTerms": this.model['Accept Terms'] || false,
              "multiLocation": this.model['Multi Location'] || false,
              "freeTour": this.model['Free Tour'] || false
            }
          }

          if (this.model['smsOptIn']) {
            details.contact.smsOptIn = this.model['smsOptIn']
          }
          if (this.model['emailOptIn']) {
            details.contact.emailOptIn = this.model['emailOptIn']
          }

          details.eventSubType = this.model['Interested In']
          details.company = this.model['Company']

          try {
            let status = await this.$mulesoftv3.leadGen(details)
            this.complete = true
          } catch(err) {
            this.error = err.response.data.message
            this.failed = true
          }
          this.sending = false
        }
      }
    },
    validate(f) {
      switch(f.type) {
        case 'checkbox':
          if(f.required && this.model[f.name] != true) {
            return false
          }
          break
        case 'date':
          if(f.required && this.model[f.name] == null) {
            return false
          }
          break
        case 'tel':
          if(f.required || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[\(]?[1-9]{1}[0-9]{2}[\)\s\-\.]*[1-9]{1}[0-9]{2}[\s\-\.]?[0-9]{4}/)
          }
          break
        case 'email':
          return this.model[f.name] && this.model[f.name].match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
          break
        default:
          if((f.required && !f.showKey) || (f.required && f.showValue.indexOf(this.model[f.showKey]) > -1) || (this.model[f.name] != null && this.model[f.name] != '')) {
            return this.model[f.name] && this.model[f.name].match(/^[A-Za-z0-9]+/)
          }
      }
      return true
    },
    openCalendar(cal) {
      this.calendar = cal
    },
    formatDate(f) {
      if(this.model[f.name] == null) {
        return f.label
      } else {
        return moment(this.model[f.name]).format(f.format)
      }
    },
    onCalendarChange(d) {
      this.model[d.name] = d.date.format('MM/DD/YYYY')
    },
    updateSelect(name, value) {
      this.model[name] = value
      if(name == 'Location') {
        this.model['Interested In'] = null
      } else if (name == 'Booking') {
        this.model['Interested In'] = null
      }
    },
    getCharsRemaining(field) {
      if(this.model[field]) {
        this.charsRemaining = 256 - this.model[field].length
      }
    },
    getWidth(f) {
      if(f.width) {
        if(f.width == 'full') {
          return 'w-full'
        } else if(f.width == 'auto') {
          return ''
        }
      }
      return 'w-full sm:w-1/2 sm:pr-2 sm:pr-8'
    },
    sortOptions(a, b) {
      if(a.title < b.title) {
        return -1
      } else if(a.title > b.title) {
        return 1
      } else {
        return 0
      }
    }
  },
}
</script>

<style lang="scss">
.locked {
  &, & > * {
    opacity: 1 !important;
  }
}
.contact.contact-bottom, .contact.bottom {
  margin-bottom: -5px;
}
</style>
